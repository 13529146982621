import { Box } from "@mui/material";
import { DeployedService } from "features";
import { FapiService } from "./FapiService";

const FapiControl = ({ services }: { services: DeployedService[] | undefined }) => {
  if (!services) {
    return <Box>Fapi service is creating!</Box>;
  }

  const fapiService = services.find((service: DeployedService) => service.name === "fapi");

  if (!fapiService) {
    return <Box>Fapi not available yet!</Box>;
  }

  return <FapiService url={fapiService.url} />;
};

export { FapiControl };
