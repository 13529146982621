import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { UserOutlineIcon } from "components/icons";
import {
  Monitor as MonitorIcon,
  MoreVertical,
  Trash as TrashIcon,
  ExternalLink as ExternalLinkIcon,
} from "react-feather";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import { Router } from "constant/router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardFooter, CardHeader, DeleteMenuItem, TypeChips } from "theme/elements";
import { elapsedTime } from "utils/dateTimes";
import { InstanceType } from "../types";
import { useDeleteInstanceMutation, useDeleteAcmInstanceMutation } from "../services";
import { useSelector } from "react-redux";
import { getUserProfile } from "services/userSlice.service";
import { InstanceStatus } from "./InstanceStatus";
import { useInstanceStatus } from "../hooks";
import { Link } from "react-router-dom";
import { INSTANCE_STATUS } from "constant";

interface PropsType {
  instance: InstanceType;
  onRefreshList: () => void;
  view?: string;
  isAcmInstance?: boolean;
}

export function InstanceCard({ instance, view = "grid", onRefreshList, isAcmInstance }: PropsType) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [deleteInstance] = useDeleteInstanceMutation();
  const [deleteAcmInstance] = useDeleteAcmInstanceMutation();
  const user = useSelector(getUserProfile);
  const navigate = useNavigate();
  const instanceStatus = useInstanceStatus(instance.status || []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const contextBtnRef = useRef<null | HTMLButtonElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteConfirm(false);
  };

  const onDeleteInstanceHandler = () => {
    setDeleteConfirm(true);
    setAnchorEl(null);
  };

  const confirmDelete = async () => {
    if (isAcmInstance) {
      await deleteAcmInstance(instance.id);
    } else {
      await deleteInstance(instance.id);
    }
    setDeleteConfirm(false);
    onRefreshList();
  };

  const onViewInstance = (event: React.MouseEvent<HTMLElement>) => {
    if (isAcmInstance && instanceStatus.status !== INSTANCE_STATUS.READY) {
      return;
    }
    if (contextBtnRef.current && !contextBtnRef.current.contains(event.target as Node)) {
      isAcmInstance
        ? navigate(Router.acmInstance().services(instance.id))
        : navigate(Router.instance().services(instance.id));
    }
  };

  const viewStyle =
    view === "list"
      ? {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          minHeight: "100px",
        }
      : {};
  const disablePointer = isAcmInstance && instanceStatus.status !== INSTANCE_STATUS.READY ? "not-allowed" : "pointer";

  return (
    <>
      <Card onClick={onViewInstance} data-testid="instance-card" sx={{ ...viewStyle, cursor: disablePointer }}>
        <CardHeader sx={{ display: "flex", alignItems: "start" }}>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
              {instance.type && <TypeChips mr={1}>{instance.type}</TypeChips>}
              <Typography variant="h4">{instance.name}</Typography>
            </Box>
            <InstanceStatus instanceStatus={instanceStatus} />
          </Box>
          {view !== "list" && (
            <Box>
              <IconButton
                data-testid="simulator-btn"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                component={Link}
                to={`/simulator/${instance.id}/open?type=${isAcmInstance ? "acm" : "vtr"}`}
                disableRipple
                sx={{ "& svg": { width: "20px" }, mr: -1 }}
              >
                <ExternalLinkIcon />
              </IconButton>
              <IconButton
                ref={contextBtnRef}
                disableRipple
                data-testid="context-menu-id"
                aria-label="more"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ "& svg": { width: "20px" }, mr: -2 }}
              >
                <MoreVertical />
              </IconButton>
            </Box>
          )}
        </CardHeader>
        <CardFooter sx={view === "list" ? { display: "flex", alignItems: "center" } : {}}>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", pb: 1 }}>
              <UserOutlineIcon />
              <Typography variant="body2" sx={{ pl: 1 }}>
                {user?.name || "Unknown"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                "& svg": { width: "14px" },
              }}
            >
              <MonitorIcon />
              <Typography variant="body2" align="right" sx={{ pl: 1 }}>
                Created {elapsedTime(instance.createdAt)}
              </Typography>
            </Box>
          </Box>
          {view === "list" && (
            <Box ml={2}>
              <IconButton
                data-testid="simulator-btn"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                component={Link}
                to={`/simulator/${instance.id}/open?type=${isAcmInstance ? "acm" : "vtr"}`}
                disableRipple
                sx={{ "& svg": { width: "20px" }, mr: -1 }}
              >
                <ExternalLinkIcon />
              </IconButton>
              <IconButton
                ref={contextBtnRef}
                data-testid="context-menu-id"
                aria-label="more"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ "& svg": { width: "20px" } }}
              >
                <MoreVertical />
              </IconButton>
            </Box>
          )}
        </CardFooter>
      </Card>
      <Dialog open={deleteConfirm} onClose={handleClose} data-testid="delete-diagram">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>If you delete you will lose everything from your instance!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton variant="text" autoFocus onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton autoFocus onClick={() => confirmDelete()} data-testid="confirm-delete-btn">
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: 3,
            paddingTop: 0,
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
          sx: {
            overflow: "visible",
            mt: 1.5,
            filter: "drop-shadow(0px 2px 5px rgba(0,0,0,0.20))",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <DeleteMenuItem onClick={onDeleteInstanceHandler} data-testid="delete-instance-menu-item">
          <ListItemIcon sx={{ "& svg": { width: "18px" } }}>
            <TrashIcon />
          </ListItemIcon>
          Delete
        </DeleteMenuItem>
      </Menu>
    </>
  );
}
