import { AcmInstanceType, DeployedService } from "../types";

export const updateServices = (item: AcmInstanceType) => {
  if (!item.services || item.services.length === 0) return [];
  const newServices = item.services.reduce((services: DeployedService[], service) => {
    const packages = service?.packages ? service.packages : [];
    return [...services, ...packages];
  }, []);
  return newServices;
};
