import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { PlusIcon, LinkTab } from "components";
import MenuItem from "@mui/material/MenuItem";
import { Router } from "constant/router";
import { useGetAllInstanceQuery, useGetAllAcmInstanceQuery } from "features";
import { HomeContainer, CustomMenu } from "theme/elements";

export function Home() {
  const navigate = useNavigate();

  const { data: instanceList = [] } = useGetAllInstanceQuery();
  const { data: acmInstanceList = [] } = useGetAllAcmInstanceQuery();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNewInstance = () => {
    navigate(Router.instance().create());
  };

  const onAcmNewInstance = () => {
    navigate(Router.acmInstance().create());
  };

  return (
    <HomeContainer data-testid="homepage">
      <Box sx={{ pb: 2 }}>
        <Typography paragraph variant="h1" data-testid="page-title">
          Overview
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={2} mb={2}>
          <Stack direction="row" spacing={2}>
            <LinkTab
              to={Router.overview().instances()}
              label={`App Instances (${instanceList.length})`}
              data-testid="app-instance"
            />
            <LinkTab
              to={Router.overview().acmInstances()}
              label={`Virtual Aircrafts (${acmInstanceList.length})`}
              data-testid="app-aircraft"
            />
          </Stack>
          <CustomButton variant="outlined" onClick={handleClick} startIcon={<PlusIcon />}>
            Create Instance
          </CustomButton>
          <CustomMenu
            id="create-instance-menu"
            aria-labelledby="create-instance-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            data-testid="create-instance-menu"
          >
            <MenuItem onClick={onNewInstance} data-testid="create-app-instance">
              Create App Instance
            </MenuItem>
            <MenuItem onClick={onAcmNewInstance} data-testid="create-acm-instance">
              Create ACM Instance
            </MenuItem>
          </CustomMenu>
        </Stack>
        <Outlet />
      </Box>
    </HomeContainer>
  );
}
