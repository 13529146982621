import { ButtonGroup, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Router } from "constant/router";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import { CustomTab } from "theme/elements";
import { FapiControl } from "lib";
import { Breadcrumbs, SectionHeader, TabPanel } from "components";
import { useInstanceStatus } from "features/instances/hooks";
import { InstanceStatus } from "features/instances/components/InstanceStatus";
import { INSTANCE_STATUS } from "constant";
import { ServiceSidebar, useGetInstanceQuery } from "features";
import { AlertCircle } from "react-feather";
import { getServiceState } from "features/instances/utils";
import { SeatIdControl } from "lib/@seatId";

export function Instance() {
  const { instanceId } = useParams();
  const { data: instance, isFetching } = useGetInstanceQuery(instanceId);
  const [value, setValue] = useState(0);

  const instanceStatus = useInstanceStatus(instance?.status || []);
  const fapiState = getServiceState("fapi", instanceStatus);
  const seatIdState = getServiceState("seatid", instanceStatus);

  if (isFetching) {
    return null;
  }

  return (
    <Box m={-4}>
      <Grid container>
        <Grid flex={1} item>
          <Box m={4} sx={{ height: "100%" }} className={"main-container"} data-testid="instance-details-container">
            <Breadcrumbs
              linkList={[
                { link: Router.overview().instances(), title: "Overview" },
                { link: Router.instance().services(instance?.id), active: true, title: "Services" },
              ]}
            />
            <SectionHeader>
              <Typography variant="h1" data-testid="service-title">
                Services
              </Typography>
            </SectionHeader>
            <Box>
              <Tabs variant="standard" value={value} aria-label="service tab" orientation="horizontal">
                <CustomTab
                  disableRipple
                  value={0}
                  data-testid="fapi-tab"
                  icon={fapiState.isWarning ? <AlertCircle data-testid="alert-icon" /> : undefined}
                  className="tab-warning"
                  label="FAPI"
                  onClick={() => setValue(0)}
                  color="textSecondary"
                  sx={{
                    color: `${value === 0 ? fapiState.color : "#9E9E9E"} !important`,
                    textTransform: "none",
                    "& svg": {
                      marginRight: "5px",
                      fill: fapiState.color,
                      stroke: "#ffffff",
                    },
                  }}
                />
                <CustomTab
                  disableRipple
                  value={1}
                  data-testid="seat-id-tab"
                  icon={seatIdState?.isWarning ? <AlertCircle data-testid="alert-icon" /> : undefined}
                  className="tab-warning"
                  label="Seat ID"
                  onClick={() => setValue(1)}
                  color=""
                  sx={{
                    color: `${value === 1 ? seatIdState?.color : "#9E9E9E"} !important`,
                    "& svg": {
                      marginRight: "5px",
                      fill: seatIdState?.color,
                      stroke: "#ffffff",
                    },
                    textTransform: "none",
                  }}
                />
              </Tabs>
            </Box>
            {instanceStatus.status === INSTANCE_STATUS.CREATING ||
            instanceStatus.status === INSTANCE_STATUS.PREPARING ? (
              <Box
                sx={{
                  minHeight: "300px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InstanceStatus instanceStatus={instanceStatus} />
              </Box>
            ) : (
              <Box>
                <TabPanel value={value} index={0} pt={4}>
                  {fapiState.isWarning ? (
                    <Box
                      color={fapiState.color}
                      sx={{
                        minHeight: "300px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {fapiState.message}
                    </Box>
                  ) : (
                    <FapiControl services={instance?.services} />
                  )}
                </TabPanel>

                <TabPanel value={value} index={1} pt={4}>
                  {seatIdState.isWarning ? (
                    <Box
                      color={seatIdState.color}
                      sx={{
                        minHeight: "300px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {seatIdState.message}
                    </Box>
                  ) : (
                    <SeatIdControl services={instance?.services} />
                  )}
                </TabPanel>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item display="flex" sx={{ borderColor: "divider", borderLeftWidth: 1, borderLeftStyle: "solid" }}>
          <ServiceSidebar services={instance?.services} status={instanceStatus} />
        </Grid>
      </Grid>
    </Box>
  );
}
