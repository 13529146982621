import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Fragment, ReactNode } from "react";
import { ChevronRight } from "react-feather";

export interface TreeItem {
  id: string;
  label: string;
  children?: TreeItem[] | undefined;
  url?: string;
  visible?: boolean;
}

export function Tree({
  defaultExpanded = false,
  items,
  parent,
  renderItem,
}: {
  items: TreeItem[];
  parent?: TreeItem | undefined;
  renderItem?: (parent: TreeItem | undefined, item: TreeItem) => ReactNode;
  defaultExpanded?: boolean;
}) {
  return (
    <Stack data-testid="service-stack">
      {items.map((item) => {
        if (Array.isArray(item.children)) {
          return (
            <Accordion
              key={item.label}
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
              disableGutters
              square
              elevation={0}
              defaultExpanded={defaultExpanded}
            >
              <AccordionSummary
                className="service-list-accordion-summary"
                sx={{
                  flexDirection: "row-reverse",
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                  "& .MuiAccordionSummary-content": {
                    marginLeft: 1,
                  },
                  padding: 0,
                }}
                expandIcon={<ChevronRight />}
              >
                <Typography>{item.label}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, pl: 4 }}>
                <Tree renderItem={renderItem} parent={item} items={item.children} />
              </AccordionDetails>
            </Accordion>
          );
        }

        return (
          <Fragment key={item.label}>
            {renderItem ? (
              renderItem(parent, item)
            ) : (
              <Box key={item.label} pt={1} pb={1} sx={{ textTransform: "capitalize" }}>
                {item.label}
              </Box>
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
}
