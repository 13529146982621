import { useEffect, useState } from "react";
import { InstanceStatusType } from "../types";
import { getCreateTime } from "utils/dateTimes";
import { INSTANCE_STATUS } from "constant";
import { getReadableServiceName } from "../utils";

export interface StatusType {
  status: string;
  shortMessage: string;
  message: string;
  svcStates: any;
}

export const useInstanceStatus = (statuses: InstanceStatusType[]) => {
  const [instanceStatus, setStatus] = useState<StatusType>({
    status: INSTANCE_STATUS.PREPARING,
    shortMessage: "Preparing...",
    message: "",
    svcStates: {},
  });

  useEffect(() => {
    if (statuses.length > 1) {
      // check status
      const currentStatus = getInstanceStatus(statuses);
      setStatus({ ...instanceStatus, ...currentStatus });
    }
  }, [statuses]);

  return instanceStatus;
};

export const getInstanceStatus = (statuses: InstanceStatusType[]) => {
  const svcStatuses = statuses.filter((status) => status.name !== "namespace");
  const readySvc = svcStatuses.filter((status) => status.status === INSTANCE_STATUS.READY);
  const svcStates: any = {};
  // console.log(readySvc.length, svcStatuses.length);
  if (readySvc.length === svcStatuses.length) {
    return { status: INSTANCE_STATUS.READY, shortMessage: "Ready to use", message: "", svcStates };
  } else {
    let criticalStatus: string = INSTANCE_STATUS.WARNING;
    let isCreatingState = true;

    statuses.forEach((status: InstanceStatusType) => {
      const svcState: any = {};
      if (status.status === INSTANCE_STATUS.READY) {
        svcState.status = INSTANCE_STATUS.READY;
      } else {
        const svcReadableName = getReadableServiceName(status.name);
        svcState.status = INSTANCE_STATUS.CREATING;
        svcState.message = `Service in creation process!`;
        const times = getCreateTime(new Date(status.lastTransitionTime));
        svcState.timeCost = times;
        // Check if creation time too long
        const totalMinutes = times.hours * 60 + times.minutes;
        if (totalMinutes > 2) {
          svcState.status = INSTANCE_STATUS.WARNING;
          svcState.message = `Warning: ${svcReadableName} taking longer time than expected!`;
          isCreatingState = false;
        }
        if (totalMinutes > 5) {
          // If creation time max 10 mins then it has critical error
          criticalStatus = INSTANCE_STATUS.ERROR;
          svcState.status = INSTANCE_STATUS.ERROR;
          svcState.message = `Error: ${svcReadableName} service creation failed. Please contact support!`;
        }
      }
      svcStates[status.name] = svcState;
    });

    if (isCreatingState) {
      return {
        status: INSTANCE_STATUS.CREATING,
        shortMessage: `Creating ${readySvc.length}/${svcStatuses.length}...`,
        message: `Creating ${readySvc.length}/${svcStatuses.length}...`,
        svcStates,
      };
    } else {
      const shortMsg =
        criticalStatus === INSTANCE_STATUS.ERROR
          ? "Error: Failed to create some services."
          : "Warning: Taking longer than expected";
      const longMsg =
        criticalStatus === INSTANCE_STATUS.ERROR
          ? "Failed: Some services were not created successfully! Please contact for support."
          : "Warning: Some service taking longer than expected!";
      return { status: criticalStatus, shortMessage: shortMsg, svcStates, message: longMsg };
    }
  }
};
