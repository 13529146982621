import { Tree, TreeItem } from "components";
import { useMemo, ReactNode } from "react";
import { ServiceListHeader } from "./ServiceListHeader";
import { capitalize } from "utils/string";
import { ServiceTypes } from "../types";

export function ServiceList({
  services,
  header = true,
  defaultExpanded = false,
  renderItem,
}: {
  services: { name: string; version: string; type: string; url?: string; visible?: boolean }[];
  renderItem?: (parent: TreeItem | undefined, item: TreeItem) => ReactNode;
  header?: boolean;
  defaultExpanded?: boolean;
}) {
  const items: TreeItem[] = useMemo(() => {
    const items = services.reduce<TreeItem[]>((accumulate, service) => {
      const index = accumulate.findIndex((item) => item.id === service.type);
      if (index === -1) {
        const svcType = service.type === ServiceTypes.ACM ? "Services" : service.type;
        return [
          ...accumulate,
          {
            id: service.type,
            label: capitalize(svcType),
            visible: service.visible,
            children: [
              {
                id: service.name,
                url: service.url,
                label: `${service.name} ${service.version || "N/A"}`,
                visible: service.visible,
              },
            ],
          },
        ];
      } else {
        accumulate[index].children?.push({
          id: service.name,
          url: service.url,
          label: `${service.name} ${service.version || "N/A"}`,
          visible: service.visible,
        });
        return accumulate;
      }
    }, []);
    // Sort by service types
    const svcTypes = Object.keys(ServiceTypes);
    items.sort((a, b) => svcTypes.indexOf(a.id) - svcTypes.indexOf(b.id));
    return items;
  }, [services]);

  if (header) {
    return (
      <ServiceListHeader>
        <Tree items={items} />
      </ServiceListHeader>
    );
  }
  return <Tree items={items} renderItem={renderItem} defaultExpanded={defaultExpanded} />;
}
